
import { defineComponent, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import FormInput from "@/components/Ui/FormInput.vue";
import FormButton from "@/components/Ui/FormButton.vue";
import Alert from "@/components/Ui/Alert.vue";
import useSellOrder from "@/composables/useSellOrder";
import { OrderForm } from "@/types/order";
import { ErrorType } from "@/types/base";

const validationSchema = yup.object({
  pay: yup.string().required("پرکردن اطلاعات این فیلد الزامی است"),
  receive: yup.string().required("پرکردن اطلاعات این فیلد الزامی است"),
});

export default defineComponent({
  name: "SellForm",
  components: {
    FormInput,
    FormButton,
    Alert,
  },
  props: {
    sellRate: {
      type: Number,
      default: 0,
    },
    defaultPay: {
      required: false,
      type: String,
      default: "",
    },
    defaultRecieve: {
      required: false,
      type: String,
      default: "",
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const { meta, handleSubmit, errors, setFieldValue } = useForm({
      validationSchema,
    });
    const { value: pay, resetField: resetPayField } = useField<
      number | string | undefined
    >("pay");
    const { value: receive, resetField: resetReceiveField } = useField<
      number | string | undefined
    >("receive");
    const whichInput = ref<"pay" | "receive">();
    const errorMessage = ref<string>();
    const disabled = ref(route.name === "Sell");
    const {
      sellOrder,
      sellOrderStatus: status,
      sellOrderError: error,
      sellOrderResponse: response,
    } = useSellOrder();

    if (props.defaultPay) {
      setFieldValue("pay", props.defaultPay);
    }
    if (props.defaultRecieve) {
      setFieldValue("receive", props.defaultRecieve);
    }

    const onSubmit = handleSubmit(async (values) => {
      const payload: OrderForm = {
        amount: String(values.pay),
        base_rate: whichInput.value === "pay" ? "usdt" : "toman",
      };

      await sellOrder(payload);

      if (status.value === "success") {
        disabled.value = true;
        router.push({
          name: "Sell",
          params: { id: response.value?.result.trackid },
          query: {
            pay: values.pay,
            receive: values.receive,
          },
        });
      }

      if (status.value === "error") {
        const { errors } = error.value as unknown as ErrorType;
        if (Array.isArray(errors.amount)) {
          errorMessage.value = errors.amount[0];
        } else {
          errorMessage.value = errors.amount as unknown as string;
        }
      }
    });

    watch(
      () => props.sellRate,
      (value) => {
        if (whichInput.value) {
          if (whichInput.value === "pay" && pay.value) {
            receive.value = parseInt(String(pay.value)) * value;
          }
          if (whichInput.value === "receive" && receive.value) {
            pay.value = parseInt(String(receive.value)) / value;
          }
        }
      }
    );

    const onUpdatePay = (value: string) => {
      pay.value = value;
      whichInput.value = "pay";
      if (props.sellRate > 0 && parseInt(value)) {
        receive.value = parseInt(value) * props.sellRate;
      } else {
        receive.value = undefined;
        resetReceiveField();
      }

      if (!value) {
        pay.value = undefined;
        resetPayField();
      }

      if (route.name === "Sell") {
        disabled.value = false;
      }
    };

    const onUpdateReceive = (value: string) => {
      receive.value = value;
      whichInput.value = "receive";
      if (props.sellRate > 0 && parseInt(value)) {
        pay.value = parseInt(value) / props.sellRate;
      } else {
        pay.value = undefined;
        resetPayField();
      }

      if (!value) {
        receive.value = undefined;
        resetReceiveField();
      }

      if (route.name === "Sell") {
        disabled.value = false;
      }
    };

    return {
      pay,
      receive,
      meta,
      errors,
      onSubmit,
      onUpdatePay,
      onUpdateReceive,
      status,
      errorMessage,
      disabled,
    };
  },
});
