
import { defineComponent, PropType, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import BuyForm from "./BuyForm.vue";
import SellForm from "./SellForm.vue";
import { vibrate } from "@/utils";
import useRate from "@/composables/useRate";
import useBreakpoints from "@/composables/useBreakpoints";
import useAuth from "@/composables/useAuth";

export default defineComponent({
  name: "TetherCalculator",
  components: {
    BuyForm,
    SellForm,
  },
  props: {
    flat: Boolean,
    activeTab: {
      required: false,
      type: String as PropType<"buy" | "sell">,
      default: "",
    },
    defaultPay: {
      required: false,
      type: String,
      default: "",
    },
    defaultRecieve: {
      required: false,
      type: String,
      default: "",
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const tab = ref<"buy" | "sell">("buy");
    const changed = ref<boolean>(false);
    const tetherCalculatorRef = ref<HTMLDivElement>();
    const { isMobile, isDesktop } = useBreakpoints();
    const { response, setTab } = useRate();
    const { user } = useAuth();

    if (props.activeTab === "sell" || props.activeTab === "buy") {
      // eslint-disable-next-line vue/no-setup-props-destructure
      tab.value = props.activeTab;
    }

    setTab(tab.value);

    const onTabClick = (value: "buy" | "sell") => {
      tab.value = value;

      if (isDesktop && user.value) {
        router.push({ name: "Order", query: { tab: value } });
      }
    };

    watch(
      () => tab.value,
      (value) => {
        changed.value = true;
        vibrate({ duration: 50, interval: 50, count: 1 });
        setTab(value);

        if (route.name === "Home" && tetherCalculatorRef.value && isMobile) {
          tetherCalculatorRef.value.scrollIntoView(true);
        }
      }
    );

    return {
      tab,
      changed,
      response,
      tetherCalculatorRef,
      onTabClick,
    };
  },
});
