import { reactive, toRefs } from "vue";
import useApi, { Status } from "./useApi";
import {
  OrderResponse,
  OrderForm,
  OrderInvoiceForm,
  setAccountForSellOrderForm,
  InvoiceForm,
} from "@/types/order";

type StateType = {
  result: OrderResponse["result"] | null;
};

const state = reactive<StateType>({
  result: null,
});

export default () => {
  const {
    call: sellOrderCall,
    status: sellOrderStatus,
    error: sellOrderError,
    response: sellOrderResponse,
  } = useApi<OrderResponse>();
  const {
    call: sellInvoiceCall,
    status: sellInvoiceStatus,
    response: sellInvoiceResponse,
  } = useApi<OrderResponse>();
  const {
    call: setAccountForSellOrderCall,
    status: setAccountForSellOrderStatus,
    error: setAccountForSellOrderError,
    response: setAccountForSellOrderResponse,
  } = useApi<OrderResponse>();
  const {
    call: invoiceCall,
    status: invoiceStatus,
    error: invoiceError,
    response: invoiceResponse,
  } = useApi<OrderResponse>();

  const sellOrder = async (data: OrderForm) => {
    await sellOrderCall({
      url: "/user/sell-order",
      method: "POST",
      data,
    });

    if (sellOrderStatus.value === Status.SUCCESS && sellOrderResponse.value) {
      state.result = sellOrderResponse.value.result;
    }
  };

  const sellInvoice = async (data: OrderInvoiceForm) => {
    await sellInvoiceCall({
      url: "/user/sell-invoice",
      method: "POST",
      data,
    });

    if (
      sellInvoiceStatus.value === Status.SUCCESS &&
      sellInvoiceResponse.value
    ) {
      state.result = sellInvoiceResponse.value.result;
    }
  };

  const setAccountForSellOrder = async (data: setAccountForSellOrderForm) => {
    await setAccountForSellOrderCall({
      url: "/user/set-account-for-sell-order",
      method: "POST",
      data,
    });

    if (
      setAccountForSellOrderStatus.value === Status.SUCCESS &&
      setAccountForSellOrderResponse.value
    ) {
      state.result = setAccountForSellOrderResponse.value.result;
    }
  };

  const invoice = async (data: InvoiceForm) => {
    await invoiceCall({
      url: "/home/invoice",
      method: "POST",
      data,
    });
  };

  return {
    ...toRefs(state),
    sellOrder,
    sellOrderStatus,
    sellOrderError,
    sellOrderResponse,
    sellInvoice,
    sellInvoiceStatus,
    sellInvoiceResponse,
    setAccountForSellOrder,
    setAccountForSellOrderStatus,
    setAccountForSellOrderError,
    invoice,
    invoiceStatus,
    invoiceResponse,
    invoiceError,
  };
};
