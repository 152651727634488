import { reactive, toRefs } from "vue";
import useApi, { Status } from "./useApi";
import {
  OrderForm,
  ChangeOrderForm,
  OrderResponse,
  OrderInvoiceForm,
  SetAddressForBuyForm,
  PayOrderResponse,
  PayOrderForm,
  InvoiceForm,
} from "@/types/order";

type StateType = {
  result: OrderResponse["result"] | null;
};

const state = reactive<StateType>({
  result: null,
});

export default () => {
  const {
    call: buyOrderCall,
    status: buyOrderStatus,
    response: buyOrderResponse,
    error: buyOrderError,
  } = useApi<OrderResponse>();
  const {
    call: changeBuyOrderCall,
    status: changeBuyOrderStatus,
    response: changeBuyOrderResponse,
    error: changeBuyOrderError,
  } = useApi<OrderResponse>();
  const {
    call: buyInvoiceCall,
    status: buyInvoiceStatus,
    response: buyInvoiceResponse,
    error: buyInvoiceError,
  } = useApi<OrderResponse>();
  const {
    call: setAddressForBuyCall,
    status: setAddressForBuyStatus,
    response: setAddressForBuyResponse,
    error: setAddressForBuyError,
  } = useApi<OrderResponse>();
  const {
    call: payOrderCall,
    status: payOrderStatus,
    response: payOrderResponse,
    error: payOrderError,
  } = useApi<PayOrderResponse>();
  const {
    call: invoiceCall,
    status: invoiceStatus,
    response: invoiceResponse,
    error: invoiceError,
  } = useApi<OrderResponse>();

  const buyOrder = async (data: OrderForm) => {
    await buyOrderCall({
      url: "/user/buy-order",
      method: "POST",
      data,
    });

    if (buyOrderStatus.value === Status.SUCCESS && buyOrderResponse.value) {
      state.result = buyOrderResponse.value.result;
    }
  };

  const changeBuyOrder = async (data: ChangeOrderForm) => {
    await changeBuyOrderCall({
      url: "/user/change-buy-order-amount",
      method: "POST",
      data,
    });

    if (
      changeBuyOrderStatus.value === Status.SUCCESS &&
      changeBuyOrderResponse.value
    ) {
      state.result = changeBuyOrderResponse.value.result;
    }
  };

  const buyInvoice = async (data: OrderInvoiceForm) => {
    await buyInvoiceCall({
      url: "/user/buy-invoice",
      method: "POST",
      data,
    });

    if (buyInvoiceStatus.value === Status.SUCCESS && buyInvoiceResponse.value) {
      state.result = buyInvoiceResponse.value.result;
    }
  };

  const setAddressForBuy = async (data: SetAddressForBuyForm) => {
    await setAddressForBuyCall({
      url: "/user/set-address-for-buy",
      method: "POST",
      data,
    });

    if (
      setAddressForBuyStatus.value === Status.SUCCESS &&
      setAddressForBuyResponse.value
    ) {
      state.result = setAddressForBuyResponse.value.result;
    }
  };

  const payOrder = async (data: PayOrderForm) => {
    await payOrderCall({
      url: "/user/pay-order",
      method: "POST",
      data,
    });
  };

  const invoice = async (data: InvoiceForm) => {
    await invoiceCall({
      url: "/home/invoice",
      method: "POST",
      data,
    });
  };

  return {
    ...toRefs(state),
    buyOrder,
    buyOrderStatus,
    buyOrderResponse,
    buyOrderError,
    changeBuyOrder,
    changeBuyOrderStatus,
    changeBuyOrderResponse,
    changeBuyOrderError,
    buyInvoice,
    buyInvoiceStatus,
    buyInvoiceResponse,
    buyInvoiceError,
    setAddressForBuy,
    setAddressForBuyStatus,
    setAddressForBuyResponse,
    setAddressForBuyError,
    payOrder,
    payOrderStatus,
    payOrderResponse,
    payOrderError,
    invoice,
    invoiceStatus,
    invoiceResponse,
    invoiceError,
  };
};
