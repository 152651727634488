
import { defineComponent, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import FormInput from "@/components/Ui/FormInput.vue";
import FormButton from "@/components/Ui/FormButton.vue";
import Alert from "@/components/Ui/Alert.vue";
import useBuyOrder from "@/composables/useBuyOrder";
import { OrderForm, ChangeOrderForm } from "@/types/order";
import { ErrorType } from "@/types/base";

const validationSchema = yup.object({
  pay: yup.string().required("پرکردن اطلاعات این فیلد الزامی است"),
  receive: yup.string().required("پرکردن اطلاعات این فیلد الزامی است"),
});

export default defineComponent({
  name: "BuyForm",
  components: {
    FormInput,
    FormButton,
    Alert,
  },
  props: {
    buyRate: {
      type: Number,
      default: 0,
    },
    defaultPay: {
      required: false,
      type: String,
      default: "",
    },
    defaultRecieve: {
      required: false,
      type: String,
      default: "",
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const { meta, handleSubmit, errors, setFieldValue } = useForm({
      validationSchema,
    });
    const { value: pay, resetField: resetPayField } = useField<
      number | string | undefined
    >("pay");
    const { value: receive, resetField: resetReceiveField } = useField<
      number | string | undefined
    >("receive");
    const whichInput = ref<"pay" | "receive">();
    const errorMessage = ref<string>();
    const disabled = ref(route.name === "Buy");
    const {
      buyOrder,
      buyOrderStatus: status,
      buyOrderResponse: response,
      buyOrderError: error,
      changeBuyOrder,
      changeBuyOrderStatus,
      changeBuyOrderResponse,
      changeBuyOrderError,
    } = useBuyOrder();

    if (props.defaultPay) {
      setFieldValue("pay", props.defaultPay);
    }
    if (props.defaultRecieve) {
      setFieldValue("receive", props.defaultRecieve);
    }

    const onSubmit = handleSubmit(async (values) => {
      // change buy order
      if (route.name === "Buy" && disabled.value === false) {
        const payload: ChangeOrderForm = {
          amount: String(values.receive),
          trackid: route.params.id as string,
        };

        await changeBuyOrder(payload);

        if (changeBuyOrderStatus.value === "success") {
          disabled.value = true;
          router.push({
            name: "Buy",
            params: { id: changeBuyOrderResponse.value?.result.trackid },
            query: {
              pay: values.pay,
              receive: values.receive,
            },
          });
        }

        if (changeBuyOrderStatus.value === "error") {
          const { errors } = changeBuyOrderError.value as unknown as ErrorType;
          if (Array.isArray(errors.amount)) {
            errorMessage.value = errors.amount[0];
          } else {
            errorMessage.value = errors.amount as unknown as string;
          }
        }
      } else {
        // buy order
        const payload: OrderForm = {
          amount: String(values.receive),
          base_rate: whichInput.value === "pay" ? "usdt" : "toman",
        };

        await buyOrder(payload);

        if (status.value === "success") {
          router.push({
            name: "Buy",
            params: { id: response.value?.result.trackid },
            query: {
              pay: values.pay,
              receive: values.receive,
            },
          });
        }

        if (status.value === "error") {
          const { errors } = error.value as unknown as ErrorType;
          if (Array.isArray(errors.amount)) {
            errorMessage.value = errors.amount[0];
          } else {
            errorMessage.value = errors.amount as unknown as string;
          }
        }
      }
    });

    watch(
      () => props.buyRate,
      (value) => {
        if (whichInput.value) {
          if (whichInput.value === "pay" && pay.value) {
            receive.value = parseInt(String(pay.value)) / value;
          }
          if (whichInput.value === "receive" && receive.value) {
            pay.value = parseInt(String(receive.value)) * value;
          }
        }
      }
    );

    const onUpdatePay = (value: string) => {
      pay.value = value;
      whichInput.value = "pay";
      if (props.buyRate > 0 && parseInt(value)) {
        receive.value = parseInt(value) / props.buyRate;
      } else {
        receive.value = undefined;
        resetReceiveField();
      }

      if (!value) {
        pay.value = undefined;
        resetPayField();
      }

      if (route.name === "Buy") {
        disabled.value = false;
      }
    };

    const onUpdateReceive = (value: string) => {
      receive.value = value;
      whichInput.value = "receive";
      if (props.buyRate > 0 && parseInt(value)) {
        pay.value = parseInt(value) * props.buyRate;
      } else {
        pay.value = undefined;
        resetPayField();
      }

      if (!value) {
        receive.value = undefined;
        resetReceiveField();
      }

      if (route.name === "Buy") {
        disabled.value = false;
      }
    };

    return {
      disabled,
      pay,
      receive,
      meta,
      errors,
      onSubmit,
      onUpdatePay,
      onUpdateReceive,
      whichInput,
      errorMessage,
      status,
    };
  },
});
