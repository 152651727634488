import { reactive, toRefs, watch, ref, onUnmounted } from "vue";
import useApi from "./useApi";
import { RateResponse } from "@/types/home";

type Tab = "buy" | "sell";

type State = {
  response: RateResponse | null;
  tab: Tab | null;
};

const state = reactive<State>({
  response: null,
  tab: null,
});

export default (fetch = true) => {
  const { call, response } = useApi<RateResponse>();
  const interval = ref<number>(0);
  let timer: number;

  const fetchRate = async () => {
    await call({
      url: "/home/rate",
      method: "GET",
    });
  };

  if (fetch) {
    fetchRate();
  }

  watch(
    () => response.value,
    (value) => {
      if (value) {
        state.response = value;
        interval.value = value.interval;
      }
    }
  );

  watch(
    () => interval.value,
    (value) => {
      if (value && response.value && fetch) {
        timer = setInterval(fetchRate, response.value.interval * 1000);
      }
    }
  );

  onUnmounted(() => {
    clearInterval(timer);
  });

  const setTab = (tab: Tab) => {
    state.tab = tab;
  };

  return {
    ...toRefs(state),
    setTab,
  };
};
